@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import 'assets/css/icons.scss';
@import 'assets/css/variables.scss';
@import 'assets/css/content.scss';
@import 'assets/css/logs.scss';
@import 'assets/css/tooltip.scss';
@import 'assets/css/checkbox.scss';
@import 'assets/css/loading-indicator.scss';
@import 'assets/css/toggle.scss';
@import 'assets/css/operators.scss';
@import 'assets/css/buttons.scss';
@import 'assets/css/icon-grid.scss';
@import 'assets/css/equipment-property.scss';
@import 'assets/css/cards.scss';
@import 'assets/css/spinning-loader.scss';



h1 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

h2 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

html {
    box-sizing: border-box;
    overflow-y: hidden;
    overflow-x: hidden;
  }

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f8f8f8;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: $gray;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*, *::before, *::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  margin: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left) !important;

}

p {
  letter-spacing: 0.3px;
  line-height: 1.5;
  margin: 2px 5px;
}

hr {
  border: 0;
  clear:both;
  display:block;
  width: 100%;
  background-color: $dark;
  height: 1px;
}

.sidebar {
  background-color: $gray-light;
  position: fixed;
  right: auto;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index:903;
  overflow:auto;
}

app-footer {
  background-color: $gray-light;
  position: fixed;
  right: 0;
  top: auto;
  left: 0;
  bottom: 0;
  z-index: 901;
  display:none;
  width: 100vw;
  height: $footerHeight;
  @media screen and (max-width: $largeScreen) {
    display: unset
  }
}
app-users, app-user-details,app-scene-group-managment, app-audit, app-live-log, app-unconfirmed-alarms, app-alarm-logs, app-project-details, app-settings, app-value, app-rooms, app-single-room, app-single-device, app-automation, app-single-automation, app-new-automation, app-scenes, app-scene-edit,app-dali-scene-edit, app-scene-group-edit,app-modes, app-modes-edit, app-modes-group-edit, app-admin-settings, app-admin-settings-buildings, app-admin-settings-apartments, app-admin-settings-rooms, app-admin-settings-room, app-user-setup-device, app-scene-groups, app-active-devices, app-temperatures, app-dali-device, app-access-log, app-doors, app-object-configurations, app-dali-scene-live {
  display:flex;
  flex-direction: column;
  background-color: $light;
  padding: 0 100px;
  padding-top: 20px;
  @media screen and (max-width: $largeScreen) {
    padding: 0 10px;
    }
  @media screen and (max-width: $smallScreen) {
    margin-left:0px;
    width: 100vw;
    }
}

app-settings{
  height: calc(100% - 25px);
  // overflow: auto
}

app-home {
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 10;
  background-color: $light;
  @media screen and (max-width: $smallScreen) {
    margin-left:0px;
    }
}

.no-side-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: unset !important;
}


  /* ** FLEXBOX ** */
.flex-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.flex-row-no-wrap {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-row-space-between {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-row-space-around {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.flex-row-space-evenly {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: flex-start;
}

.flex-column {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-column-center {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;
}

.flex-column-center-full {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}


.flex-row-center {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

.flex-row-center-vertical {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

.flex-row-center-full {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.space-between{
  justify-content:space-between
}
.space-around{
  justify-content:space-around
}

.align-self-center {
  align-self: center
}

.flex-equip-prop {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.align-center {
  align-items: center;
}

section {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.no-wrap {
  flex-wrap: unset;
}

.from-back {
  justify-content: flex-end;
}

.display-none {
  &:before {
    display: none;
  }
}

.flex-row-objects {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: flex-start;
}


  /* ** MARGIN, PADDING, WIDTH, ... , ** */

  @mixin attr-x($attr, $attr-count: 10, $attr-steps: 10, $unit: '%') {
    $attr-list: null;
    @for $i from 1 through $attr-count {
        $attr-value: $attr-steps * $i;

        .#{$attr}#{$attr-value} {
            #{$attr}: #{$attr-value}#{$unit};
        }

        $attr-list: append($attr-list, unquote(".#{$attr}-#{$attr-value}"), comma);
    }
}
// (property, number of iterations, iteration step X value, measurement unit)
// bottom line create classes .margin-left5 , .margin-left10, ... , .margin-left30
@include attr-x('margin-left', 20, 5, 'px');
@include attr-x('margin-right', 20, 5, 'px');
@include attr-x('margin-top', 20, 5, 'px');
@include attr-x('margin-bottom', 20, 5, 'px');

@include attr-x('margin-left', 10, 1, 'px');
@include attr-x('margin-right', 10, 1, 'px');
@include attr-x('margin-top', 10, 1, 'px');
@include attr-x('margin-bottom', 10, 1, 'px');

@include attr-x('padding-left', 2, 5, 'px');
@include attr-x('width',20,5,'%');
@include attr-x('font-size',50,1,px);

.to-right {
  margin-left: auto ;
}

.to-left {
  margin-right: auto ;
}

.margin-left45-neg {
  margin-left: -45px;
}

.width-auto {
  width: auto;
}

.width-full {
  width: 100%;
}

.width-90p {
  width: 90%;
}

.width-200 {
  width:200px
}

.width-150 {
  width:150px;
}

.width-125 {
  width:125px;
}

.width-100 {
  width:100px
}

.width-75 {
  width:75px;
}

.width-125 {
  width:125px;
}

.width-50 {
  width:50px;
}

.width-32 {
  width:32px;
}

.width-full-minus-arrow {
  width: calc(100% - 32px);
}

  /* ** BUTTON ** */


button {
  border: none;
  background-color: $primary;
  color: $light;
  padding: 8px 22px;
  margin: 10px 5px;
  cursor: pointer;
  transition: all 0.5s;
}

.button-disabled {
  background-color: $disabled-button !important
}


button:hover {
  background-color: $ocean-blue;
  color: white;
}

  /* ** TEXT ** */

  .orange {
    color: #ED634A;
  }

  .background-orange {
    background-color: #ED634A !important;
  }

  .ocean-blue {
    color: $ocean-blue;
  }

  .text-align-center {
    text-align: center;
  }

  .text-align-right {
    text-align: right
  }

  .contents {
    display: contents;
  }

  .center {
    text-align: center;
}

  .new-line {
      display: block;
  }

  .big-letters {
    font-size: 28px;
    font-weight: 700;
}

.blue-text {
  color: $ocean-blue;
}

.text-in-color {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 10px;
  color: $ocean-blue;
  text-align: center;
  text-transform: uppercase;
}

.uppercase {
  text-transform: uppercase;
}

.white-text {
  color: white;
}

.bold {
  font-weight: bold;
}
  /* ** BORDER ** */

  .br {
    border: 2px solid $secondary;
  }

  .br-r {
    border-right: 2px solid $secondary;
  }

  .br-r-s {
    border-right: 1px solid $secondary-dark;
  }

  .br-l {
    border-left: 2px solid $secondary;
  }

  .br-l-s {
    border-left: 1px solid $secondary-dark;
  }

  .br-b {
    border-bottom: 2px solid $secondary;
  }

  .br-b-s {
    border-bottom: 1px solid $secondary-dark;
  }

  .br-rounded {
    border-radius: 15px;
  }

  .white-line {
    background-color: white;
    margin: 10px 0 5px 0;
}

.margin-top-bottom20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-5 {
  margin: 5px
}

.margin-10 {
  margin: 10px
}
.margin-20 {
  margin: 20px
}
.padding-15 {
  padding: 15px
}



  /* ** TAB ** */
  .tab {
    width: 48%;
    height: 40px;
    text-align: center;
    margin: 0px;
    padding: 8px;
    font-size: 1.1rem;
    border-radius: 5px;
    cursor: pointer;
  }

  .tab-in-row {
    // width: 50%;
    text-align: center;
    margin: 0px;
    padding: 3px 20px;
    font-size: 1.1rem;
    border-radius: 5px;
    background-color: $gray-light;
    box-shadow: 3px 3px #e2e2e2;
    cursor: pointer;
  }

  .tab:hover {
    background-color: $ocean-blue-light;
    color: white;
  }

  .tab-selected {
    color: white;
    background-color: $ocean-blue;
  }


  /* ** MISC ** */

  .no-transform {
    &:hover {
        transform: none !important;
        box-shadow: none !important;
      }
}

  .middle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: clamp(0.8rem, 0.728em + 1.86vw, 1.6rem);
  }

.hidden {
  visibility: hidden
}

.fab-container {
  position: sticky;
  bottom: 5px;
}

.clickable {
  cursor: pointer;
}

.not-clickable {
  cursor: default;
}

.no-top {
  top: 0px ;
}

.rotate {
  transform: rotateZ(180deg);
  transform-origin:center center;
  transition-duration: .5s;
}

.rotate-back {
  transform: rotateZ(0deg);
  transform-origin:center center;
  transition-duration: .5s;
}


.outline-none {
  outline: none;
}

.no-click {
  pointer-events: none !important;
  cursor: unset !important;
}

.rotate180 {
  transform: rotate(180deg);
}

.prop-editing {
  border-radius: 10px 10px 0px 0px;
  position: absolute;
  background-color: white;
  bottom: 0%;
  width: 80vw;
  height:60vh;
  margin-left: 10vw;
  min-height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.background-layer {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 9998;
  background-color: $modal-background
}


.equipment-property-modal {
  --border-radius: 10px 10px 0px 0px;
  --width: 80vw;
  --height:60vh;
}


.equipment-property-modal .modal-wrapper {
  bottom: 0;
  position: absolute;

}

.pin-modal-container {
  --border-radius: 10px;
  --max-width: 250px;
  --max-height: 280px;
}


.blindsv2-settings-modal-container {
  --border-radius: 10px;
  --max-width: 350px;
  --max-height: 360px;
}

.toast-msg {
  --width: 200px;
}

.pos-absolute {
  position: absolute;
}

.pos-relative {
  position: relative;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-2-rows {
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.alarm-subscription-modal {
  --border-radius: 10px 10px 10px 10px;
  --height:85vh;
  --width: 95vw;
  --max-width:500px;
}


.spiner-color-secondary {
  --spinner-color: #6BA0C4;
}

.square-primary {
  --border-radius:0px;
  --background: rgb(3, 20, 58);
}

.square-secondary {
  --border-radius:0px;
  --background: rgb(107, 160, 196);
}

.device-title {
  text-align: center;
  font-size: xx-large;
  margin-top: 20px;
  margin-bottom: 20px;
}

.stretch {
  align-items: stretch
}

.color-radio {
  // --color: rgb(3, 20, 58);
  --color: rgb(3, 20, 58);
  --color-checked: rgb(3, 20, 58);
}


// from single room scss
.i-power-bl{
  background: transparent url('/assets/images/power_bl.svg') no-repeat center center;
    padding: 14px;
    background-size: 20px;
    cursor: pointer;
}

.i-power-on-bl{
  background: transparent url('/assets/images/power_bl_on.svg') no-repeat center center;
    padding: 14px;
    background-size: 20px;
    cursor: pointer;
}

.i-power-off-bl{
  background: transparent url('/assets/images/power_bl_off.svg') no-repeat center center;
    padding: 14px;
    background-size: 20px;
    cursor: pointer;
}


/* .i-lightbulb-black {
  background: transparent url('/assets/images/lightbulb-black.svg') no-repeat center center;
  padding: 15px;
  background-size: 18px;
} */

.i-light-on {
  background: transparent url('/assets/images/light-bulb-on.svg') no-repeat center center;
  padding: 20px 10px 20px 10px;
  background-size: 25px;
}

.i-lightbulb-large-orange {
  background: transparent url('/assets/images/lightbulb-orange.svg') no-repeat center center;
  padding: 40px 20px 40px 20px;
  background-size: 30px;
}

.i-lightbulb-large-black {
  background: transparent url('/assets/images/lightbulb-black.svg') no-repeat center center;
  padding: 40px 20px 40px 20px;
  background-size: 30px;
}

.i-light-group {
  background: transparent url('/assets/images/three-light-bulbs.svg') no-repeat center center;
  padding: 15px;
  background-size: 30px;
}


.i-blinds {
  background: transparent url('/assets/images/blinds.svg') no-repeat center center;
  padding: 16px;
  background-size: 25px;
}

.i-flood {
  background: transparent url('/assets/images/flood.svg') no-repeat center center;
  padding: 16px;
  background-size: 25px;
}

.i-wind {
  background: transparent url('/assets/images/wind.svg') no-repeat center center;
  padding: 16px;
  background-size: 25px;
}

.i-security-equipment {
  background: transparent url('/assets/images/security_equipment_bl.svg') no-repeat center center;
  padding: 14px;
  background-size: 21px;
}

.i-security-equipment-alarm {
  background: transparent url('/assets/images/security_equipment_alarm.svg') no-repeat center center;
  padding: 14px;
  background-size: 21px;
  animation: blink 0.7s steps(1, start) infinite;
  -webkit-animation: blink 0.7s steps(1, start) infinite;
}

.i-security-equipment-alarm-md {
  background: transparent url('/assets/images/security_equipment_alarm.svg') no-repeat center center;
  padding: 8px 14px 14px 14px;
  background-size: 16px;
  // animation: blink 0.7s steps(1, start) infinite;
  // -webkit-animation: blink 0.7s steps(1, start) infinite;
}

.i-safety-equipment {
  background: transparent url('/assets/images/safety_equipment_bl.svg') no-repeat center center;
  padding: 20px 15px 20px 15px;
  background-size: 25px;
}


.i-safety-equipment-alarm {
  background: transparent url('/assets/images/safety_equipment_alarm.svg') no-repeat center center;
  padding: 20px 15px 20px 15px;
  background-size: 25px;
  animation: blink 0.7s steps(1, start) infinite;
  -webkit-animation: blink 0.7s steps(1, start) infinite;
}

.i-safety-equipment-alarm-md {
  background: transparent url('/assets/images/safety_equipment_alarm.svg') no-repeat center center;
  padding: 15px 12px 15px 12px;
  background-size: 23px;
  // animation: blink 0.7s steps(1, start) infinite;
  // -webkit-animation: blink 0.7s steps(1, start) infinite;
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink {
  to {
    visibility: hidden;
  }
}

.i-general-equipment {
  background: transparent url('/assets/images/general_equipment_bl.svg') no-repeat center center;
  padding: 20px;
  background-size: 28px;
}

.i-details {
  background: transparent url('/assets/images/three-vert-dots.svg') no-repeat center center;
  padding: 20px 10px 20px 10px;
  background-size: 22px;
  cursor: pointer;
}
